import React, {Component} from "react";
import styled from "styled-components";

import Resources from "../../resources/afisofom-resources.json"

const PrivConfig = Resources.privacy_policy;
const PrivPolTitle = PrivConfig.title;

const MainWrapper = styled.div `
    padding: 1.5em 0;
`
const Title = styled.h1 `
    font-family: var(--font-normal);
    color: var(--main-color);
    font-size: 2em;
`
const SubTitle = styled.h4 `
    font-family: var(--font-bold);
    margin-bottom: 0;
    margin-top: 2em;
`

const PrivPol = styled.div `
    color: var(--main-color-text);
    text-align: left;
    line-height:1.5em;
    font-family: var(--font-normal);

    .reduced-upper-margin {
        margin-top: .3em;
    }
    `
const PrivP = styled.p `
    margin-top: .3em;
    line-height: 1.5em;
`

const PrivOl = styled.ol `
    counter-reset: list;
    margin-bottom: 0em;
    padding-left: 0;
`

const PrivLi = styled.li `
    list-style: none;
    :before {
        counter-increment: list;
    }

    padding-bottom: 1.5em;    
`

const PrivLiAlpha = styled(PrivLi) `
    :before {
        content: counter(list, lower-alpha) ") ";
    }
`

const PrivLiDecimal = styled(PrivLi) `
    :before {
        content: counter(list, decimal) ") ";
    }
`

const Bold = styled.span `
    font-family: var(--font-bold);
`
const AFISofom = () => (
    <Bold>Análisis Filtro e Índices SAPI de C.V. SOFOM., E.N.R.</Bold>
);

class PrivacyPolicy extends Component {
    
    render() {
        return (
            <div className={"container"}>
                <MainWrapper>
                    <Title>{PrivPolTitle}</Title>
                    <PrivPol>
                        <PrivP><AFISofom />, pone a disposición nuestra política de privacidad y del cómo salvaguardamos la integridad, privacidad y protección de sus datos personales, en apego a la Ley.</PrivP>
                        
                        <SubTitle>INFORMACIÓN DE CONTACTO</SubTitle>
                        <PrivP><AFISofom /><br />
                        Domicilio: Calle Minerva No. 56, Colonia Crédito Constructor,<br />
                        Delegación Benito Juárez, C.P. 03940 México, D.F.<br />
                        Teléfonos: (55) 2614 0920 / (55) 5273 3929</PrivP>

                        <SubTitle>PRINCIPIO DE INFORMACIÓN</SubTitle>
                        <PrivP>Cada responsable le informa que posee, recaba o recabará de usted, los datos personales necesarios, para la adecuada realización de las operaciones y prestación de los servicios, así como para la celebración de los demás actos que cada responsable puede realizar conforme a la Ley y sus estatutos sociales. Dichos datos personales pueden haber sido o pueden ser obtenidos de usted, ya sea personalmente o bien, directamente por cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología. Asimismo, podemos obtener datos personales de los que usted es titular, a través de terceros y de otras fuentes permitidas por la ley, tales como las sociedades de información crediticia. Los datos personales recabados o que se recaban podrán incluir, entre otros, los siguientes:</PrivP>
                        <PrivOl>
                            <PrivLiAlpha>Datos de identificación (incluyendo los derivados de sus características físicas), de contacto, laborales, financieros o patrimoniales.</PrivLiAlpha>
                        </PrivOl>

                        <SubTitle className="reduced-upper-margin">FINALIDADES DEL USO DE DATOS PERSONALES</SubTitle>
                        <PrivP>La obtención, uso, divulgación o almacenamiento, incluyendo sin limitar, el acceso, manejo, aprovechamiento, transferencia o disposición (en adelante el “Tratamiento”) que llevamos a cabo de los datos personales de nuestros clientes, tiene como finalidades específicas las siguientes:</PrivP>
                        <PrivOl>
                            <PrivLiAlpha>el cumplimiento de las finalidades previstas en el principio de información de este aviso de privacidad; y</PrivLiAlpha>
                            <PrivLiAlpha>a fines distintos que resulten compatibles o complementarios a los establecidos en este aviso de privacidad, sin que para ello se requiera obtener nuevamente, en su caso, el consentimiento del titular.</PrivLiAlpha>
                        </PrivOl>
                        <PrivP>Asimismo, el tratamiento de datos personales será el que resulte necesario, adecuado y relevante en relación con las finalidades previstas en este aviso de privacidad, así como con los fines distintos que resulten compatibles o complementarios relacionados con los servicios que proporciona <AFISofom /></PrivP>

                        <SubTitle>LOS DATOS PERSONALES PUEDEN SER COMUNICADOS A TERCEROS</SubTitle>
                        <PrivP>El titular de los datos personales que presenta una solicitud y/o entabla una relación jurídica con <AFISofom />  mismas que se describen en el presente Aviso de Privacidad consiente la transferencia de sus datos personales dentro e incluso fuera de la República Mexicana en los términos que se describen.
                        Cada responsable se compromete a velar porque se cumplan todos los principios legales de protección en torno a la transferencia de sus datos personales y manifiesta su compromiso para que se respete en todo momento, por <AFISofom />  y por nuestros socios comerciales, el presente aviso de privacidad y las finalidades a que el titular sujeto su tratamiento.</PrivP>
                        <PrivP>Las transferencias nacionales o internacionales de datos podrán llevarse a cabo sin el consentimiento del titular, entre otros casos cuando:</PrivP>
                        <PrivOl>
                            <PrivLiAlpha>Este prevista en una Ley o tratado en los que México sea parte;</PrivLiAlpha>
                            <PrivLiAlpha>Sea necesaria para la prevención o diagnóstico médico, la prestación de asistencia sanitaria, tratamiento médico o la gestión de servicios sanitarios; </PrivLiAlpha>
                            <PrivLiAlpha>Sea efectuada a sociedades controladoras, subsidiarias o afiliadas bajo el control común del responsable de que se trate, o a una sociedad matriz o cualquier sociedad del mismo grupo del responsable que opere bajo los mismos procesos y políticas internas;</PrivLiAlpha>
                            <PrivLiAlpha>Sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, por el responsable y un tercero;</PrivLiAlpha>
                            <PrivLiAlpha>Sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular.</PrivLiAlpha>
                        </PrivOl>
                        <PrivP>Cualquiera de las responsables podrá efectuar transferencias de datos personales, además de los supuestos antes mencionados, de manera enunciativa más no limitativa, con fines de prospección comercial de servicios complementarios a los proporcionados por las empresas señaladas en el presente Aviso de Privacidad.</PrivP>

                        <SubTitle>EJERCICIOS DE LOS DERECHOS DE ARCO</SubTitle>
                        <PrivP>Los clientes de <AFISofom /> tienen el derecho de conocer los datos personales que recabamos de ellos, los detalles del tratamiento que llevamos a cabo de los mismos, rectificarlos en caso de ser inexactos, instruirnos a cancelar dichos datos personales cuando consideren que resulten ser excesivos o innecesarios para las finalidades necesarias o no necesarias que justificaron su obtención u oponerse al Tratamiento de los mismos para fines específicos:</PrivP>
                        <PrivP>Para llevar a cabo el ejercicio de cualquiera de los derechos antes mencionados, nuestros clientes podrán presentar una solicitud de acceso/rectificación/cancelación u oposición ante nuestro Departamento de Protección de Datos Personales cuyos datos de ubicación y contacto han quedado debidamente identificados.</PrivP>
                        <PrivP>La solicitud deberá contener:</PrivP>
                        <PrivOl>
                            <PrivLiDecimal>Nombre, domicilio, teléfono, correo electrónico y número de contrato.</PrivLiDecimal>
                            <PrivLiDecimal>Estar debidamente firmada por el solicitante que a su vez deberá ser cliente de <AFISofom /></PrivLiDecimal>
                            <PrivLiDecimal>Identificación Oficial; en caso de representantes de personas morales, los documentos que los acrediten como tales.</PrivLiDecimal>
                            <PrivLiDecimal>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer alguno de los derechos antes mencionados.</PrivLiDecimal>
                            <PrivLiDecimal>Para el caso de solicitudes de rectificación de datos personales, además especificar las modificaciones a realizarse y aportar la documentación que sustente la petición.</PrivLiDecimal>
                        </PrivOl>

                        <SubTitle>LIMITACIÓN DE EL USO O DIVULGACIÓN DE DATOS PERSONALES</SubTitle>
                        <PrivP>Los clientes de <AFISofom /> podrán en el momento en que lo consideren conveniente presentar su solicitud para el limitar o revocar su consentimiento para el tratamiento y/o transferencia de sus datos personales con fines complementarios, señalados en el presente Aviso de Privacidad.</PrivP>
                        <PrivP>De igual forma tendrán siempre la opción de limitar la utilización de sus datos personales con fines de comercialización, inscribiéndose en el Registro de Usuarios (REUS) a cargo de Comisión Nacional de Defensa de los Usuarios de Servicios Financieros. Además de lo anterior y con la finalidad de limitar el uso y divulgación de los datos personales de nuestros clientes, le informamos que mantenemos mecanismos y medidas de seguridad que garantizan el correcto Tratamiento de dichos datos personales y los protegen contra daño, pérdida, alteración, destrucción o el uso, acceso o Tratamiento no autorizado.</PrivP>
                        <PrivP>Entre dichos mecanismos y medidas, se encuentran la suscripción de cartas y convenios de confidencialidad con nuestros prestadores de servicios, proveedores y/o terceros, a través de las cuales dichas personas se comprometen a Tratar los datos personales de nuestros clientes de conformidad con las finalidades del presente aviso y a guardar estricta confidencialidad de los mismos. </PrivP>

                        <SubTitle>PROTOCOLO DE SEGURIDAD EN LÍNEA</SubTitle>
                        <PrivP>El Usuario que tenga acceso a la página, conviene en recibir archivos que les transmitan los servidores de <AFISofom /> dichos archivos pueden contener información tal como la identificación proporcionada por el Usuario o información para rastrear las páginas que el Usuario ha visitado.</PrivP>

                        <SubTitle>MODIFICACIONES AL PRESENTE AVISO DE PRIVACIDAD</SubTitle>
                        <PrivP> Nos reservamos el derecho de efectuar modificaciones o actualizaciones al presente aviso de privacidad en cualquier momento. Lo anterior, sea para la atención de novedades legislativas o jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de nuestros productos y servicios y prácticas del mercado.</PrivP>
                        <PrivP>Estas modificaciones estarán disponibles a nuestros clientes a través de uno o varios de los siguientes medios: (i) anuncios visibles en nuestro establecimiento o centro de atención a clientes; (ii) trípticos o folletos disponibles en nuestras oficinas; (iii) en nuestra página de Internet (www.afisofom.com) sección aviso de privacidad); (iv) a través del envío de las mismas al último correo electrónico que nuestros clientes nos hayan proporcionado; y/o (v) vía telefónica al último número telefónico de casa, oficina o celular que nuestros clientes nos hayan proporcionado.</PrivP>
                        <PrivP>Si usted considera que su derecho de protección de datos personales ha sido vulnerado por alguna conducta de nuestros empleados o de nuestras actuaciones o respuestas, puede contactar a nuestra Dirección de Protección de Datos Personales, Email: asolorzano@afisofom.com o a los teléfonos (55) 2614 0920 (D.F.) (55) 5273 3929 horario de Lunes a Viernes de 9:00 a 17:00 Hrs. y sin perjuicio de su derecho de acudir ante el Instituto Federal de Acceso a la Información y Protección de Datos Personales (IFAI). Para mayor información visite www.ifai.org.mx.</PrivP>
                    </PrivPol>
                </MainWrapper>
            </div>
        );
    }
}

export default PrivacyPolicy
