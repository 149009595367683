import React from "react"
import PrivacyPolicy from "../components/privacyPolicy"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/layout.css"

const AvisoDePrivacidad = () => (
    <Layout fixedNavbar={true} location="privPolicy" showContact={false}>
      <SEO title="Aviso de Privacidad" />
      <PrivacyPolicy />
    </Layout>
  )
  
  export default AvisoDePrivacidad
  